<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="consentFormVaccine" v-model="formValid">
          <!-- alergies -->
          <v-radio-group v-model="formData.allergies" class="label-custom" :rules="ruleRequired">
            <template v-slot:label>
              <div>{{ questions.allergies }}</div>
            </template>
            <v-radio :value="yes">
              <template v-slot:label>
                <div>Yes</div>
              </template>
            </v-radio>
            <v-radio :value="no">
              <template v-slot:label>
                <div>No</div>
              </template>
            </v-radio>
            <v-radio :value="dontKnow">
              <template v-slot:label>
                <div>{{ dontKnow }}</div>
              </template>
            </v-radio>
            <v-radio :value="consultedFamilyDoc">
              <template v-slot:label>
                <div>{{ consultedFamilyDoc }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <!-- codiv past weeks -->
          <v-radio-group v-model="formData.covidPastWeeks" class="label-custom" :rules="ruleRequired">
            <template v-slot:label>
              <div>{{ questions.covidPastWeeks }}</div>
            </template>
            <v-radio :value="yes">
              <template v-slot:label>
                <div>Yes</div>
              </template>
            </v-radio>
            <v-radio :value="no">
              <template v-slot:label>
                <div>No</div>
              </template>
            </v-radio>
          </v-radio-group>

          <!-- pregnant -->
          <v-radio-group v-model="formData.pregnant" class="label-custom" :rules="ruleRequired">
            <template v-slot:label>
              <div>
                {{ questions.pregnant }}
              </div>
            </template>
            <v-radio :value="yes">
              <template v-slot:label>
                <div>Yes</div>
              </template>
            </v-radio>
            <v-radio :value="no">
              <template v-slot:label>
                <div>No</div>
              </template>
            </v-radio>
            <v-radio :value="dontKnow">
              <template v-slot:label>
                <div>{{ dontKnow }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <!-- breast cancer -->
          <v-radio-group v-model="formData.breastCancer" class="label-custom" :rules="ruleRequired" @change="breastCancerOnChange">
            <template v-slot:label>
              <div>{{ questions.breastCancer }}</div>
            </template>
            <v-radio :value="yesLeft">
              <template v-slot:label>
                <div>{{ yesLeft }}</div>
              </template>
            </v-radio>
            <v-radio :value="yesRight">
              <template v-slot:label>
                <div>{{ yesRight }}</div>
              </template>
            </v-radio>
            <v-radio :value="yesBoth">
              <template v-slot:label>
                <div>{{ yesBoth }}</div>
              </template>
            </v-radio>
            <v-radio :value="no">
              <template v-slot:label>
                <div>No</div>
              </template>
            </v-radio>
          </v-radio-group>

          <!-- anesthesia -->
          <v-radio-group v-model="formData.anesthesia" class="label-custom" :rules="ruleRequired">
            <template v-slot:label>
              <div>{{ questions.anesthesia }}</div>
            </template>
            <v-radio :value="yes">
              <template v-slot:label>
                <div>Yes</div>
              </template>
            </v-radio>
            <v-radio :value="no">
              <template v-slot:label>
                <div>No</div>
              </template>
            </v-radio>
          </v-radio-group>

          <!-- bleeding tendency -->
          <v-radio-group v-model="formData.bleedingTendency" class="label-custom" :rules="ruleRequired">
            <template v-slot:label>
              <div>{{ questions.bleedingTendency }}</div>
            </template>
            <v-radio :value="yes">
              <template v-slot:label>
                <div>Yes</div>
              </template>
            </v-radio>
            <v-radio :value="no">
              <template v-slot:label>
                <div>No</div>
              </template>
            </v-radio>
            <v-radio :value="dontKnow">
              <template v-slot:label>
                <div>{{ dontKnow }}</div>
              </template>
            </v-radio>
            <v-radio :value="consultedFamilyDoc">
              <template v-slot:label>
                <div>{{ consultedFamilyDoc }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <!-- epilepsy -->
          <v-radio-group v-model="formData.epilepsy" class="label-custom" :rules="ruleRequired">
            <template v-slot:label>
              <div>
                {{ questions.epilepsy }}
              </div>
            </template>
            <v-radio :value="yes">
              <template v-slot:label>
                <div>Yes</div>
              </template>
            </v-radio>
            <v-radio :value="no">
              <template v-slot:label>
                <div>No</div>
              </template>
            </v-radio>
            <v-radio :value="consultedFamilyDoc">
              <template v-slot:label>
                <div>{{ consultedFamilyDoc }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-divider></v-divider>

          <v-alert type="error" dense class="mt-4" v-if="formError"> Please fill in all required fields </v-alert>
          <v-card-actions class="mt-4">
            <v-spacer></v-spacer>
            <v-btn v-if="parentType == 'requestVaccine'" text @click="closeModal()" large :disabled="cannotContinue || submitting"> Cancel </v-btn>
            <v-btn color="primary" @click="next()" large :disabled="cannotContinue || submitting" :loading="submitting"> Next </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- vaccine dialog -->
    <v-dialog v-model="cannotContinue" persistent max-width="480">
      <v-card>
        <v-card-title class="headline error white--text" primary-title>Cannot continue</v-card-title>
        <v-card-text class="pt-8 subtitle-1">{{ dialogMessage }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" outlined @click="restart()"> Restart </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- breast cancer dialog -->
    <v-dialog v-model="showBreastCancerModal" persistent max-width="480">
      <v-card>
        <v-card-title class="headline primary white--text" primary-title>Attention</v-card-title>
        <v-card-text class="pt-8 subtitle-1">Please indicate at your vaccine appointment that you have or have had breast cancer</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" outlined @click="showBreastCancerModal = false"> Understood </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ConsentFormFieldKeys } from '@/views/VaccineViews/VaccineRegistrationPage/data/consent-form-field-keys.js';
import { ConsentFormQuestions } from '@/views/VaccineViews/VaccineRegistrationPage/data/consent-form-questions.js';

export default {
  props: ['parentType', 'submitBtnText', 'submitting'],
  components: {},
  data() {
    return {
      yes: 'Yes',
      no: 'No',
      dontKnow: "I don't know",
      yesLeft: 'Yes, on the left side',
      yesRight: 'Yes, on the right side',
      yesBoth: 'Yes, on both sides',
      consultedFamilyDoc: 'I have consulted my family doctor and I can take the COVID19 vaccine',
      formData: new ConsentFormFieldKeys(),
      formDataPrestine: new ConsentFormFieldKeys(),
      questions: new ConsentFormQuestions(),
      cannotContinue: false,
      formValid: null,
      dialogMessage: null,
      ruleRequired: [(v) => !!v || 'This field is required'],
      formError: false,
      showBreastCancerModal: false,
    };
  },
  watch: {},
  methods: {
    next() {
      this.formError = false;
      if (this.$refs['consentFormVaccine'].validate()) {
        this.cannotContinue = false;
        if (this.checkAnswers()) {
          this.dialogMessage = this.getDialogMessage();
          this.cannotContinue = true;
        } else this.goToNextStep();
      } else this.formError = true;
    },
    restart() {
      this.formData = this.formDataPrestine;
      this.cannotContinue = false;
      this.dialogMessage = null;
      this.$refs['consentFormVaccine'].reset();
      window.scrollTo(0, 0);
    },
    goToNextStep() {
      this.$emit('nextStep', this.formData);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    checkAnswers() {
      for (const key in this.formData) {
        if (this.formData[key] == this.yes || this.formData[key] == this.dontKnow) {
          if (key != 'breastCancer') return true;
        }
      }
      return false;
    },
    getDialogMessage() {
      const consultDocFirst = 'Please consult your family doctor to know if you can get the COVID-19 vaccine';
      let msg = consultDocFirst;
      if (this.formData['allergies'] == this.yes || this.formData['allergies'] == this.dontKnow) {
        msg = consultDocFirst;
      } else if (this.formData['covidPastWeeks'] == this.yes || this.formData['covidPastWeeks'] == this.dontKnow) {
        msg = 'Please wait for scheduling the appointment until 4 weeks after the start of COVID-19 symptoms have passed';
      } else if (this.formData['pregnant'] == this.yes) {
        msg = 'Please wait for scheduling the appointment after the birth of your child';
      } else if (this.formData['pregnant'] == this.dontKnow) {
        msg = 'Appointment can NOT be made. Contact your doctor or take a pregnancy test';
      } else if (this.formData['anesthesia'] == this.yes) {
        msg =
          'Please wait for scheduling the appointment until you have had your medical intervention under full anesthesia or consult your family doctor';
      } else if (this.formData['bleedingTendency'] == this.yes || this.formData['bleedingTendency'] == this.dontKnow) {
        msg = 'You should first discuss with your treating physician whether special precautions are needed';
      } else if (this.formData['epilepsy'] == this.yes) {
        msg = consultDocFirst;
      }
      return msg;
    },
    breastCancerOnChange(answer) {
      if (answer && answer.toLowerCase().includes('yes')) this.showBreastCancerModal = true;
      else this.showBreastCancerModal = false;
    },
  },
};
</script>

<style lang="scss">
.label-custom {
  legend {
    height: auto !important;
  }
}
</style>