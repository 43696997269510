export class ConsentFormFieldKeys {
  constructor() {
    return {
      allergies: null,
      covidPastWeeks: null,
      pregnant: null,
      breastCancer: null,
      anesthesia: null,
      bleedingTendency: null,
      epilepsy: null
    };
  }
}
