export class ConsentFormQuestions {
  constructor() {
    return {
      allergies: 'Do you have allergies to medication, food, a vaccine component or latex? ',
      covidPastWeeks: 'Do you have COVID19 now or have you had COVID19 in the past 4 weeks?',
      pregnant: 'Are you pregnant or is there a chance you could become pregnant during the next months?',
      breastCancer: 'Have you had breast cancer?',
      anesthesia: 'Are you scheduled for a medical intervention under full anesthesia in the following months?',
      bleedingTendency: 'Do you have an increased bleeding tendency, congenital or acquired (through medication)?',
      epilepsy: 'Are you familiar with epilepsy and did you have a epileptic seizure while having a fever or after a vaccination?'
    };
  }
}
