<template>
  <div :id="id" class="print-subject-registration hide-print">
    <div class="registration-info-wrapper" style="margin-bottom: 30px; overflow: hidden">
      <!-- registration data -->
      <h3 style="margin-bottom: 3px">COVID-19 Vaccine Registration Info:</h3>
      <div class="text" style="float: left; width: 300px">
        <table class="print-subject-registration-table table-sm" v-if="formData" style="margin-top: 5px">
          <tbody>
            <tr>
              <td>First Name:</td>
              <td>{{ formData.firstName }}</td>
            </tr>
            <tr>
              <td>Last Name:</td>
              <td>{{ formData.lastName }}</td>
            </tr>
            <tr>
              <td>Birth Date:</td>
              <td>{{ computedBirthDate }}</td>
            </tr>
            <tr>
              <td>Gender:</td>
              <td>{{ formData.gender }}</td>
            </tr>
            <tr v-if="!noEmail">
              <td>E-Mail:</td>
              <td>{{ formData.email }}</td>
            </tr>
            <tr>
              <td>Phone number:</td>
              <td>{{ formData.phoneNumber }}</td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>{{ formData.accommodationAddress }}</td>
            </tr>
            <tr v-if="formData.azvNumber">
              <td>AZV number:</td>
              <td>{{ formData.azvNumber }}</td>
            </tr>
            <tr v-if="computedDoctorName">
              <td>Doctor Name:</td>
              <td>{{ computedDoctorName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="float: left">
        <vue-qrcode :value="'^^' + patientCase + '^'" :scale="5" />
      </div>
    </div>

    <!-- questions -->
    <div style="margin-top: 20px; clear: both" v-if="consentFormData">
      <h3 style="margin-bottom: 3px">Consent Questions:</h3>
      <div v-for="(item, i) in consentQuestions" :key="i + '-question'" style="font-size: 14px">
        <div class="quest-answer-wrapper" v-if="consentFormData[i]" style="margin-bottom: 3px">
          <span>{{ item }}</span>
          <span style="margin-left: 5px">
            <strong>{{ consentFormData[i] }}</strong>
          </span>
        </div>
      </div>
    </div>

    <!-- terms and conditions -->
    <div class="terms" style="margin-top: 30px; font-size: 12px">
      <h4 style="margin-bottom: 3px">STATEMENTS, WARRANTIES, TERMS & CONDITIONS:</h4>
      <p style="margin-top: 5px">
        By applying for, or confirming my request to my general practitioner to register me to receive a Covid -19 vaccination, I agree and consent to
        the following Terms and Conditions and declare as follows:
      </p>
      <ul>
        <li>My application for the Covid-19 vaccin is voluntary.</li>

        <li>The Covid-19 vaccin is free of charge.</li>

        <li>
          I declare that my general practitioner is aware of all my medical conditions and therefor is able to determine my qualification for the
          Covid-19 vaccin.
        </li>

        <li>
          Upon applying for the Covid 19 vaccin the system will generate two appointments for me, the second appointment is about 3 weeks after the
          first one.
        </li>

        <li>
          I understand that in case I am ill or experience symptoms of Covid-19 I cannot receive my vaccine dosis and will need to cancel my
          appointment and reschedule.
        </li>

        <li>I cannot choose the brand of vaccination nor the vaccination location, this shall be done by the health authorities.</li>

        <li>
          I agree and consent that my vaccination is registered and my anonymized data is used by the DVG for public health analysis related to the
          Covid -19.
        </li>

        <li>
          I agree and consent that any side effects that I may experience are reported to the public health authorities who will send such to the
          ‘bijwerkingen centrum LAREB’ for further analysis, such data may be kept for 20 years.
        </li>

        <li>
          I indemnify and shall hold the country of Aruba harmless for any side effect I may experience related to the Covid -19 vaccine itself.
        </li>
      </ul>
    </div>

    <!-- signature -->
    <div style="max-width: 300px; margin-top: 80px">
      <div style="margin-bottom: 50px">Registrant signature:</div>
      <hr />
      <div style="font-size: 13px">Date: {{ formatDate(date) }}</div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import moment from 'moment';
import { ConsentFormQuestions } from '@/views/VaccineViews/VaccineRegistrationPage/data/consent-form-questions.js';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  props: ['formData', 'patientCase', 'id', 'noEmail', 'consentFormData'],
  components: {
    VueQrcode,
  },
  data() {
    return {
      consentQuestions: new ConsentFormQuestions(),
      date: moment(),
    };
  },
  computed: {
    computedBirthDate() {
      return this.formData.dateOfBirth ? moment(this.formData.dateOfBirth, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    },
    computedDoctorName() {
      if (this.formData.doctorEntry) return this.getDoctorNameFromId(this.formData.doctorEntry.id);
      else return null;
    },
  },
  mounted() {},
  methods: {
    getDoctorNameFromId(id) {
      if (this.$store.state.doctorOptions.length > 0 && id) {
        let selectedDoctor = this.$store.state.doctorOptions.filter((doctor) => id == doctor.value);
        return selectedDoctor[0].text;
      }
    },
  },
};
</script>